<template>
  <div class="IssueFieldDate">
    <component
      :is="date.isEmpty || !date.isValid ? 'span' : 'time'"
      v-for="(date, ix) in dates"
      :key="[ix, date.iso].join('~')"
      class="IssueFieldDate__date"
      :datetime="date.isEmpty || !date.isValid ? null : date.iso"
      v-text="date.display"
    />
  </div>
</template>

<script>
import moment from 'moment'
import * as R from 'ramda'

import { fmtDate } from '../helpers'

export default {
  name: 'IssueFieldDate',

  props: {
    field: { type: Object, required: true },
    value: { type: [Array, String, Number], default: null },
  },

  computed: {
    dates() {
      const { field, value } = this
      return field.isList
        ? R.is(Array, value) ? value.map(v => this.explainDate(v)) : [this.explainDate(null)]
        : [this.explainDate(value)]
    },
  },

  methods: {
    explainDate(value) {
      const isEmpty = value == null || value === ''
      const date = isEmpty ? null : moment(value)
      const isValid = isEmpty ? true : date.isValid()

      return {
        isEmpty,
        isValid,
        iso: date && date.format('YYYY-MM-DD'),
        display: R.cond([
          [() => isEmpty, R.always('–')],
          [() => !isValid, R.always('Invalid date')],
          [R.T, fmtDate],
        ])(date),
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.IssueFieldDate
  &__date
    display: block
</style>
