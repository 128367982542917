var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"TriScore",attrs:{"data-score":_vm.score}},_vm._l((3),function(i){return _c('div',{key:i,staticClass:"TriScore__dot",class:{
      [_vm.color]: i <= _vm.score
    },style:({
      background: i <= _vm.score ? null : 'rgba(66, 66, 92, .1)',
      width: `${_vm.width}px`,
      height: `${_vm.height}px`,
      borderRadius: `${_vm.borderRadius}px`,
    })})}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }