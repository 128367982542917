<template>
  <div class="IssueFieldFloat">
    <div
      v-for="(value, ix) in values"
      :key="[ix, value].join('-')"
      class="IssueFieldFloat__value"
      v-text="value == null ? '–' : value.toFixed(10)"
    />
  </div>
</template>

<script>
export default {
  name: 'IssueFieldFloat',

  props: {
    field: { type: Object, required: true },
    value: { type: [Array, Number], default: null },
  },

  computed: {
    values() {
      const { field, value } = this
      const { isList } = field

      return isList
        ? value == null ? [null] : value.map(v => parseFloat(v))
        : [value]
    },
  },
}
</script>

<style lang="sass" scoped>
// .IssueFieldFloat
 //  &__value
</style>
