import { addEventListenerBeforeDestroy, replaceRoute } from '../helpers'

// keyboard navigation with [<-] and [->]
export default ({
  // listening to this `event` on `document`
  event = 'keydown',
  preventDefault = false,
  // disable navigation if any modifier (Ctrl, Shift, Alt, Meta) is pressed
  ignoreModifiers = true,

  // routes (in terms of `VueRouter`) for navigation
  props: {
    leftRoute = 'previousRoute',
    rightRoute = 'nextRoute',
    upRoute = null,
    downRoute = null,
    homeRoute = null,
    endRoute = null,
  } = {},

  // optionally: call a method on navigation completed
  methods: {
    done = null,
  } = {},
} = {}) => {
  const getHandler = routeProp => async (vm, e) => {
    if (vm.$store.state.defaultLayout.disableGlobalShortcuts) return
    if (preventDefault) e.preventDefault()

    const { [routeProp]: route } = vm
    if (route != null) {
      await replaceRoute(vm.$router, route)
      if (done && vm[done]) vm[done]()
    }
  }

  const onArrowLeft = getHandler(leftRoute)
  const onArrowRight = getHandler(rightRoute)
  const onArrowUp = upRoute ? getHandler(upRoute) : () => {}
  const onArrowDown = downRoute ? getHandler(downRoute) : () => {}
  const onHome = upRoute ? getHandler(homeRoute) : () => {}
  const onEnd = downRoute ? getHandler(endRoute) : () => {}

  return {
    mounted() {
      addEventListenerBeforeDestroy(this, document, event, e => {
        // ignore keyboard events with modifiers
        if (
          ignoreModifiers &&
          (e.altKey || e.ctrlKey || e.metaKey || e.shiftKey)
        ) return

        switch (e.key || e.code) {
          case 'ArrowLeft': return onArrowLeft(this, e)
          case 'ArrowRight': return onArrowRight(this, e)
          case 'ArrowUp': return onArrowUp(this, e)
          case 'ArrowDown': return onArrowDown(this, e)
          case 'Home': return onHome(this, e)
          case 'End': return onEnd(this, e)
        }
      })
    },
  }
}
