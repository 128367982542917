<!-- displays severity/score (i.e. of an issue) as three dots:
  `score` = 0 -> one green dot
  `score` = 1 -> two orange dots
  `score` = 2 -> three red dots -->

<template>
  <div
    class="TriScore"
    :data-score="score"
  >
    <div
      v-for="i in 3"
      :key="i"
      class="TriScore__dot"
      :class="{
        [color]: i <= score
      }"
      :style="{
        background: i <= score ? null : 'rgba(66, 66, 92, .1)',
        width: `${width}px`,
        height: `${height}px`,
        borderRadius: `${borderRadius}px`,
      }"
    />
  </div>
</template>

<script>
import { SCORE_LOOKUP, SCORE } from '../constants'

export default {
  name: 'TriScore',

  props: {
    score: {
      type: Number,
      default: null,
      validator: score => SCORE.map(s => s.value).includes(score),
    },
    width: { type: Number, default: 8 },
    height: { type: Number, default: 4 },
    borderRadius: { type: Number, default: 2 },
  },

  computed: {
    color() {
      const { score } = this
      return SCORE_LOOKUP[score]?.color ?? 'grey'
    },
  },
}
</script>

<style lang="sass" scoped>
  .TriScore
    display: flex

    &__dot
      transition: background-color 250ms ease-in, width 250ms ease-in, height 250ms ease-in, border-radius 250ms ease-in

      &:not(:first-child)
        margin-left: 2px
</style>
