<template>
  <div class="IssueExportRecords">
    <IssueExportRecord
      v-for="record in records"
      :key="record.id"
      :record="record"
      class="IssueExportRecords__record"
    />
  </div>
</template>

<script>
import IssueExportRecord from './IssueExportRecord'

export default {
  name: 'IssueExportRecords',

  components: {
    IssueExportRecord,
  },

  props: {
    // see Issue.exportRecords in swagger spec
    records: { type: Array, required: true },
  },
}
</script>

<style lang="sass" scoped>
.IssueExportRecords
  max-width: 100%
  text-overflow: ellipsis

  &__record
    margin: 4px 0
</style>
