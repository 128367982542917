<template>
  <div class="IssueFieldRequest">
    <div
      v-for="(request, ix) in requests"
      :key="ix"
      class="IssueFieldRequest__value"
    >
      <template v-if="request == null">
        –
      </template>
      <template v-else>
        <PreCopy
          title="Request"
          :text="request.request"
        />
        <PreCopy
          title="Response"
          :text="request.response"
        />
      </template>
    </div>
  </div>
</template>

<script>
import PreCopy from './PreCopy'

export default {
  name: 'IssueFieldRequest',

  components: {
    PreCopy,
  },

  props: {
    field: { type: Object, required: true },
    value: { type: [Array, Object], default: null },
  },

  computed: {
    requests() {
      const { field, value } = this
      const { isList } = field

      return isList
        ? value == null ? [null] : value
        : value
    },
  },
}
</script>

<style lang="sass" scoped>
.IssueFieldRequest
  &__value
    & + &
      margin-top: 24px
</style>
