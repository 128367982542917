<!-- displays severity/score (i.e. of an issue) as a colored badge:
  `score` = 2 -> red badge with the text "High"
  `score` = 1 -> orange badge with the text "Medium"
  `score` = 0 -> green badge with the text "Low"

  after the label (High/Medium/Low) there's a slot #append for extra content

  colors and labels are configurable through props -->

<template>
  <span
    class="SlaBadge"
    :class="{
      ['SlaBadge--' + state]: true,
      'SlaBadge--disabled': !editable || completedDatetime != null,
      'SlaBadge--transparent': color === TRANSPARENT,
    }"
    v-on="$listeners"
  >
    <v-menu
      bottom
      offset-y
      :close-on-content-click="false"
      :disabled="!editable || completedDatetime != null"
    >
      <template #activator="{ on: menuOn, attrs: menuAttrs }">
        <v-tooltip
          bottom
          :disabled="!tooltip"
          max-width="200"
        >
          <template #activator="{ on: tooltipOn, attrs: tooltipAttrs }">
            <span
              v-ripple="editable && completedDatetime == null ? { class: 'app-ripple' } : false"
              class="SlaBadge__badge"
              v-bind="{ ...tooltipAttrs, ...menuAttrs, ...bind }"
              @mouseenter="hover = true"
              @mouseleave="hover = false"
              v-on="{ ...$listeners, ...tooltipOn, ...menuOn }"
            >
              <slot
                :hover="hover"
                :inactive="inactive"
              >
                <span class="d-inline-flex align-center mr-auto">
                  <span
                    v-if="state !== STATE.completedOnTime"
                    class="SlaBadge__label"
                    v-text="daysLeft == null ? '–' : daysLeft"
                  />
                  <span
                    v-else
                    style="height: 16px; width: 16px"
                    class="d-inline-flex align-center justify-center mr-auto"
                  >
                    <v-icon
                      color="success"
                      v-text="'mdi-check'"
                    />
                  </span>

                  <v-icon
                    v-if="manuallyOverriden"
                    style="margin-left: 2px"
                    class="SlaBadge__manual-icon"
                    small
                    v-text="'mdi-account-outline'"
                  />
                </span>
              </slot>
              <slot name="append" />
            </span>
          </template>
          <div>
            <div v-text="tooltip" />
            <div
              v-if="manuallyOverriden"
              style="color: #C1C1D2"
              v-text="$t('sla.SlaChangedManually')"
            />
          </div>
        </v-tooltip>
      </template>

      <v-sheet
        color="white"
        class="pa-2"
      >
        <v-menu
          v-model="datePickerIsOpen"
          offset-y
          bottom
          :close-on-content-click="false"
          :disabled="datePickerDisabled"
        >
          <template #activator="{ attrs }">
            <div
              v-bind="attrs"
              @click.stop.prevent.capture="!datePickerDisabled && (datePickerIsOpen = !datePickerIsOpen)"
              @keydown.capture.stop.enter="!datePickerDisabled && (datePickerIsOpen = !datePickerIsOpen)"
              @keydown.capture.stop.space="!datePickerDisabled && (datePickerIsOpen = !datePickerIsOpen)"
              @keydown.capture.stop.down="!datePickerDisabled && (datePickerIsOpen = true)"
              @keydown.capture.stop.up="!datePickerDisabled && (datePickerIsOpen = false)"
              @keydown.capture.stop.esc="!datePickerDisabled && (datePickerIsOpen = false)"
            >
              <AppTextField
                class="SlaBadge__date-text-field"
                :class="{
                  'SlaBadge__date-text-field--popup-open': datePickerIsOpen,
                  'SlaBadge__date-text-field--disabled': datePickerDisabled,
                  ['SlaBadge__date-text-field--' + state]: true,
                }"
                :label="$t('sla.RemediateTill')"
                filled
                readonly
                :disabled="datePickerDisabled"
                :value="model ? fmtDate(model) : $t('sla.NoSla') "
                append-icon="mdi-menu-down"
              />
            </div>
          </template>

          <v-sheet color="white">
            <v-date-picker
              :value="model"
              :min="startDate"
              @change="
                model = $event;
                saveChanges();
                datePickerIsOpen = false;
              "
            />
          </v-sheet>
        </v-menu>

        <div class="d-flex justify-space-between align-center">
          <v-btn
            :disabled="saving || !manuallyOverriden"
            text
            plain
            color="primary"
            @click="reset"
          >
            <v-icon
              class="mr-2"
              v-text="'mdi-refresh'"
            />
            {{ $t('sla.Reset') }}
          </v-btn>

          <AppSwitch
            v-model="switchModel"
            :label="switchModel ? $t('sla.on') : $t('sla.off')"
            hide-details
            :reversed="false"
            :disabled="saving"
            class="mt-0 pt-0"
          />
        </div>
      </v-sheet>
    </v-menu>
  </span>
</template>

<script>
import moment from 'moment'
import * as R from 'ramda'

import { fmtDate } from '../helpers'

import Issue from '../store/orm/issue'

const TRANSPARENT = 'transparent'

const STATE = Object.freeze({
  unset: 'unset',
  normal: 'normal',
  warning: 'warning',
  overdue: 'overdue',
  completedOnTime: 'completedOnTime',
  completedWithOverdue: 'completedWithOverdue',
})

const BG = Object.freeze({
  [STATE.unset]: TRANSPARENT,
  [STATE.normal]: '#D6FFEA',
  [STATE.warning]: '#FAF4D6',
  [STATE.overdue]: '#FBDFD7',
  [STATE.completedOnTime]: TRANSPARENT,
  [STATE.completedWithOverdue]: TRANSPARENT,
})

const isValidDate = v => moment(v, 'YYYY-MM-DD', true).isValid()
const isValidDatetime = v => moment(v).isValid()

export default {
  name: 'SlaBadge',

  props: {
    issueId: { type: String, required: true },

    startDatetime: {
      type: String,
      required: true,
      validator: isValidDatetime,
    },
    slaDate: {
      type: String,
      default: null,
      validator: date => date == null || isValidDate(date),
    },
    manuallyOverriden: {
      type: Boolean,
      default: false,
    },
    completedDatetime: {
      type: String,
      default: null,
      validator: date => date == null || isValidDatetime(date),
    },
    warningFraction: {
      type: Number,
      default: 0.15,
      validator: v => !Number.isNaN(v) && v >= 0 && v <= 1,
    },
    warningDays: {
      type: Number,
      default: 1,
      validator: v => !Number.isNaN(v) && v >= 0,
    },

    inactive: { type: Boolean, default: false },
    inactiveColor: { type: String, default: '#3C3A52' },
    inactiveHoverColor: { type: String, default: '#343246' },

    editable: { type: Boolean, default: false },
  },

  data() {
    return {
      STATE,
      TRANSPARENT,

      hover: false,
      model: this.slaDate,
      datePickerIsOpen: false,
      saving: false,
    }
  },

  computed: {
    startDate() { return moment(this.startDatetime).format('YYYY-MM-DD') },

    // If completed - days remaining after completion (`slaDate` - `completedDate`)
    // Else - days remaining before `slaDate` (`slaDate` - `now`)
    daysLeft() {
      const { model: slaDate, completedDatetime } = this
      if (!slaDate) return null
      let sla = moment(slaDate)
      const completedOrNow = completedDatetime ? moment(completedDatetime).endOf('day') : moment()
      sla = sla.isBefore(completedOrNow)
        ? sla.clone().startOf('day')
        : sla.clone().endOf('day')
      const daysLeft = sla.diff(completedOrNow, 'days')
      return daysLeft >= 0
        // We never show 0, if it expires today - we show 1, tomorrow - 2, etc.
        ? daysLeft + 1
        : daysLeft
    },

    daysTotal() {
      const { model: slaDate, startDatetime } = this
      if (!slaDate) return null
      const start = moment(startDatetime)
      const sla = moment(slaDate)
      if (start.clone().startOf('day').isAfter(sla)) {
        console.error('Invalid SLA date, it is erroneously earlier than start date')
        return 0
      }
      return sla.clone().endOf('day').diff(start.clone(), 'days') + 1
    },

    state() {
      const { daysTotal, daysLeft, warningFraction, warningDays, completedDatetime } = this
      if (daysTotal == null || daysLeft == null) return STATE.unset
      if (daysTotal <= 0) {
        console.warn('Cannot compute valid SLA state, defaulting to unset')
        return STATE.unset
      }
      if (daysLeft <= 0) {
        return completedDatetime == null ? STATE.overdue : STATE.completedWithOverdue
      }
      if (completedDatetime != null) return STATE.completedOnTime
      const timeLeftFraction = daysLeft / daysTotal
      if (timeLeftFraction <= warningFraction || daysLeft <= warningDays) return STATE.warning
      return STATE.normal
    },

    color() {
      const {
        inactive, inactiveColor, inactiveHoverColor,
        hover, state,
      } = this

      if (inactive) {
        return hover ? inactiveHoverColor : inactiveColor
      }

      return BG[state]
    },

    bindColor() {
      const { color } = this
      const bindColor =
        color.startsWith('#') ||
        color.startsWith('rgb(') ||
        color.startsWith('rgba(') ||
        color === TRANSPARENT
          ? { style: { backgroundColor: color } }
          : { class: { [color]: true }, style: {} }
      if (color !== TRANSPARENT) {
        bindColor.style.color = '#41415A'
      }
      return bindColor
    },

    bind() {
      const { inactive, bindColor } = this
      return R.mergeDeepRight({
        class: {
          'SlaBadge--inactive': inactive,
        },
      }, bindColor)
    },

    tooltip() {
      const { state, model, completedDatetime } = this
      if (state === STATE.unset) return this.$t('sla.NoSla')
      if (state === STATE.normal) return this.$t('sla.RemediateTillM', { date: moment(model).format('ll') })
      if (state === STATE.warning) return this.$t('sla.SoonToIverdueM', { date: moment(model).format('ll') })
      if (state === STATE.overdue) return this.$t('sla.OverdueRemediateTillM', { date: moment(model).format('ll') })
      if (state === STATE.completedOnTime) return this.$t('sla.RemediatedOnTimeM', { date: moment(completedDatetime).format('ll') })
      if (state === STATE.completedWithOverdue) return this.$t('sla.OverdueRemediatedM', { date: moment(completedDatetime).format('ll') })
      return null
    },

    switchModel: {
      get() { return this.model != null },
      set(turnOnSla) {
        this.model = turnOnSla
          ? moment().format('YYYY-MM-DD')
          : null
        this.saveChanges()
      },
    },

    datePickerDisabled() {
      const { saving, model } = this
      return saving || !model
    },
  },

  watch: {
    slaDate(slaDate) {
      if (this.model !== slaDate) this.model = slaDate
    },
  },

  methods: {
    fmtDate,

    async reset() {
      const { issueId } = this
      if (this.saving) return
      this.saving = true
      try {
        await Issue.dispatch('$update', {
          issue: { id: issueId },
          slaRecalculate: true,
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.saving = false
      }
    },

    async saveChanges() {
      const { model, issueId } = this
      if (this.saving) return
      this.saving = true
      try {
        await Issue.dispatch('$update', {
          issue: { id: issueId, sla: model },
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.saving = false
      }
    },
  },
}
</script>

<style lang="sass">
// @import '../scss/variables'

.SlaBadge
  display: inline-block
  height: 24px
  transition: all 150ms ease
  border-radius: 2px

  &__badge
    display: inline-flex
    border-radius: 2px
    padding: 4px 4px 4px 6px
    transition: all 250ms ease-in
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 16px
    letter-spacing: 0.09375em
    font-feature-settings: 'cv04' on

  &:hover
    background: rgba(0, 0, 0, .05)

  &--disabled:hover
    background: transparent

  &__label
    color: inherit

  .theme--dark &--transparent &__manual-icon
    color: white !important
  .theme--dark &:not(#{&}--transparent) &__manual-icon
    color: rgba(0, 0, 0, 0.54) !important

  &__date-text-field

    &:not(&--disabled)
      cursor: pointer !important
      *
        cursor: pointer !important

    .v-input__append-inner
      margin-top: auto !important
      margin-bottom: auto !important
      .v-icon
        transition: all 150ms ease

    &--popup-open
      .v-input__append-inner .v-icon
        transform: rotate(180deg)

    &--overdue input
      color: var(--v-error-base, red) !important
</style>
