var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover } = { hover: false }){return [_c('v-carousel-item',{staticClass:"GalleryDialogSlide",attrs:{"src":_vm.isImage ? _vm.file.url : null,"contain":_vm.isImage ? true : null}},[_c('v-slide-y-reverse-transition',[_c('v-overlay',{directives:[{name:"show",rawName:"v-show",value:(_vm.file.caption && hover),expression:"file.caption && hover"}],staticClass:"GalleryDialogSlide__overlay",attrs:{"absolute":"","color":"rgba(50, 50, 50, .2)"}},[_c('v-sheet',{staticClass:"GalleryDialogSlide__overlay-caption mt-auto pa-4",attrs:{"color":"rgba(0, 0, 0, .7)","elevation":"2","rounded":""}},[_vm._v(" "+_vm._s(_vm.file.caption)+" ")])],1)],1),_c('v-slide-y-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isImage || hover),expression:"!isImage || hover"}],staticClass:"GalleryDialogSlide__attachment"},[_c('v-sheet',{style:({
              marginBottom: _vm.isImage ? 'auto' : null,
              marginTop: !_vm.isImage && !_vm.isText ? '48px' : null,
            }),attrs:{"color":"rgba(0, 0, 0, .5)","elevation":"2","rounded":"","dark":""}},[_c('a',{staticClass:"GalleryDialogSlide__attachment-link pa-4",on:{"click":function($event){return _vm.downloadFile(_vm.file)}}},[_c('v-icon',{staticClass:"GalleryDialogSlide__attachment-link-icon",attrs:{"x-large":""},domProps:{"textContent":_vm._s('mdi-download')}}),_c('div',{domProps:{"textContent":_vm._s(_vm.file.fileName)}})],1)]),(_vm.isText)?_c('pre',{staticClass:"GalleryDialogSlide__attachment-preview elevation-2 rounded",domProps:{"textContent":_vm._s(
              _vm.textContent ||
                (_vm.loading && _vm.$t('issue.Loading')) ||
                _vm.$t('issue.FilePreviewUnavailableM')
            )},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}}):_vm._e()],1)])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }