<!-- displays severity/score (i.e. of an issue) as a colored badge:
  `score` = 2 -> red badge with the text "High"
  `score` = 1 -> orange badge with the text "Medium"
  `score` = 0 -> green badge with the text "Low"

  after the label (High/Medium/Low) there's a slot #append for extra content

  colors and labels are configurable through props -->

<template>
  <span
    class="ColorScore"
    v-bind="bind"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    v-on="$listeners"
  >
    <slot
      :hover="hover"
      :mini="mini"
      :dark="dark"
      :dense="dense"
      :denser="denser"
      :label="label"
      :inactive="inactive"
    >
      <span
        class="ColorScore__label mr-auto"
        v-text="label"
      />
    </slot>
    <slot name="append" />
  </span>
</template>

<script>
import * as R from 'ramda'

import { SCORE, SCORE_LOOKUP, SCORE_INFO, SCORE_LOW, SCORE_MEDIUM, SCORE_HIGH, SCORE_CRITICAL } from '../constants'
import i18n from '../i18n'

const INFO = SCORE_LOOKUP[SCORE_INFO]
const LOW = SCORE_LOOKUP[SCORE_LOW]
const MEDIUM = SCORE_LOOKUP[SCORE_MEDIUM]
const HIGH = SCORE_LOOKUP[SCORE_HIGH]
const CRITICAL = SCORE_LOOKUP[SCORE_CRITICAL]

export default {
  name: 'ColorScore',

  props: {
    score: {
      type: Number,
      default: null,
      validator: score =>
        SCORE.map(s => s.value).includes(score),
    },

    mini: { type: Boolean, default: false }, // shows only the first letter of the label
    dark: { type: Boolean, default: true }, // use white font
    dense: { type: Boolean, default: false }, // smaller padding
    denser: { type: Boolean, default: false }, // even smaller variant

    infoLabel: { type: String, default: i18n.t(INFO.displayValue) },
    infoColor: { type: String, default: INFO.color },

    lowLabel: { type: String, default: i18n.t(LOW.displayValue) },
    lowColor: { type: String, default: LOW.color },

    mediumLabel: { type: String, default: i18n.t(MEDIUM.displayValue) },
    mediumColor: { type: String, default: MEDIUM.color },

    highLabel: { type: String, default: i18n.t(HIGH.displayValue) },
    highColor: { type: String, default: HIGH.color },

    criticalLabel: { type: String, default: i18n.t(CRITICAL.displayValue) },
    criticalColor: { type: String, default: CRITICAL.color },

    inactive: { type: Boolean, default: false },
    inactiveColor: { type: String, default: '#3C3A52' },
    inactiveHoverColor: { type: String, default: '#343246' },
  },

  data: () => ({
    hover: false,
  }),

  computed: {
    color() {
      const {
        inactive, inactiveColor, inactiveHoverColor,
        hover, score,
        infoColor, lowColor, mediumColor, highColor, criticalColor,
      } = this

      if (inactive) {
        return hover ? inactiveHoverColor : inactiveColor
      }

      return {
        0: infoColor,
        1: lowColor,
        2: mediumColor,
        3: highColor,
        4: criticalColor,
      }[score] ?? 'grey'
    },

    bindColor() {
      const { color } = this
      return color.startsWith('#')
        ? { style: { backgroundColor: color } }
        : { class: { [color]: true } }
    },

    bind() {
      const { dark, inactive, dense, denser, bindColor } = this
      return R.mergeDeepRight({
        class: {
          'white--text': dark || !inactive,
          'ColorScore--dense': dense && !denser,
          'ColorScore--denser': !dense && denser,
        },
      }, bindColor)
    },

    label() {
      const { score, mini } = this
      const label = {
        0: this.infoLabel,
        1: this.lowLabel,
        2: this.mediumLabel,
        3: this.highLabel,
        4: this.criticalLabel,
      }[score] ?? this.$t('dashboard.Unknown')

      return mini ? label.slice(0, 1) : label
    },
  },
}
</script>

<style lang="sass" scoped>
  @import '../scss/variables'

  .ColorScore
    display: inline-flex
    border-radius: $border-radius-root
    padding: 10px
    transition: background-color 250ms ease-in, padding 250ms ease-in

    &.ColorScore--dense
      padding: 6px
      font-weight: 500
      font-size: 12px
      line-height: 100%
      letter-spacing: normal
      // text-transform: capitalize
      font-feature-settings: 'cv04' on

    &.ColorScore--denser
      padding: 2px 3px
      font-weight: 700
      font-size: 11px
      line-height: 100%
      letter-spacing: 0.02em

    &__label
      color: inherit
      overflow: hidden
      text-overflow: ellipsis
</style>
