<template>
  <div class="IssueFieldMarkdown">
    <AppMarkdownViewer
      v-for="(value, ix) in values"
      :key="ix"
      :source="value"
      class="IssueFieldMarkdown__viewer"
    />
  </div>
</template>

<script>
export default {
  name: 'IssueFieldMarkdown',

  props: {
    field: { type: Object, required: true },
    value: { type: [Array, String], default: null },
  },

  computed: {
    values() {
      const { field, value } = this
      const { isList } = field

      return isList
        ? value == null ? [null] : value.map(String)
        : [value]
    },
  },
}
</script>

<style lang="sass" scoped>
// .IssueFieldMarkdown
 //  &__viewer
</style>
