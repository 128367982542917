<template>
  <div class="IssueFieldInteger">
    <div
      v-for="(value, ix) in values"
      :key="[ix, value].join('-')"
      class="IssueFieldInteger__value"
      v-text="value == null ? '–' : value"
    />
  </div>
</template>

<script>
export default {
  name: 'IssueFieldInteger',

  props: {
    field: { type: Object, required: true },
    value: { type: [Array, Number], default: null },
  },

  computed: {
    values() {
      const { field, value } = this
      const { isList } = field

      return isList
        ? value == null ? [null] : value.map(v => parseInt(v, 10))
        : [value]
    },
  },
}
</script>

<style lang="sass" scoped>
// .IssueFieldInteger
 //  &__value
</style>
