<template>
  <div class="IssueFieldString">
    <div
      v-for="value in parsedValues"
      :key="value.key"
      class="IssueFieldString__value"
      v-text="value.displayValue"
    />
  </div>
</template>

<script>
import * as R from 'ramda'

export default {
  name: 'IssueFieldString',

  props: {
    field: { type: Object, required: true },
    value: { type: [Array, String], default: null },
  },

  computed: {
    values() {
      const { field, value } = this
      const { isList } = field

      return isList
        ? value == null ? [null] : value.map(String)
        : [value]
    },

    parsedValues() {
      const { field, values } = this
      const { allowedValues } = field

      const plainToOption = (v, ix) => ({
        value: v,
        displayValue: v == null || v === '' ? '–' : v,
        key: [ix, v].join('-'),
      })

      return allowedValues?.length
        ? values.map((v, ix) => {
          const foundValue = allowedValues.find(opt => opt.value === v)
          return foundValue
            ? R.assoc('key', [ix, v].join('-'), foundValue)
            : plainToOption(v, ix)
        })
        : values.map(plainToOption)
    },
  },
}
</script>

<style lang="sass" scoped>
// .IssueFieldString
 //  &__value
</style>
