<template>
  <div class="IssueFieldBoolean">
    <v-checkbox
      v-for="(value, ix) in values"
      :key="[ix, value].join(',')"
      :class="ix < (values.length - 1) && 'pa-0 ma-0'"
      hide-details
      readonly
      dense
      :input-value="value"
      :indeterminate="value == null"
      :label="field.displayName"
    />
  </div>
</template>

<script>
export default {
  name: 'IssueFieldBoolean',

  props: {
    field: { type: Object, required: true },
    value: { type: [Array, Boolean], default: null },
  },

  computed: {
    values() {
      const { field, value } = this
      const { isList } = field

      return isList
        ? value == null ? [null] : value.map(Boolean)
        : [value]
    },
  },
}
</script>

<style lang="sass" scoped>
// .IssueFieldBoolean
</style>
