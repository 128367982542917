<template>
  <v-dialog
    v-model="isOpenInner"
    class="GalleryDialog"
    :max-width="1200"
    @keydown.left="prev"
    @keydown.right="next"
  >
    <v-sheet color="#38364D">
      <v-carousel
        v-model="galleryModel"
        height="90vh"
        show-arrows-on-hover
        autofocus
      >
        <GalleryDialogSlide
          v-for="file in files"
          :key="file.uuid"
          :file="file"
        />
      </v-carousel>
    </v-sheet>
  </v-dialog>
</template>

<script>
import * as R from 'ramda'

import GalleryDialogSlide from './GalleryDialogSlide'

const ixByUuid = uuid => R.findIndex(R.propEq('uuid', uuid))

export default {
  name: 'GalleryDialog',

  components: {
    GalleryDialogSlide,
  },

  props: {
    isOpen: { type: Boolean, default: true }, // .sync
    files: { type: Array, default: () => [] },
    value: { type: String, default: null }, // active file uuid, .sync
  },

  data: () => ({
    model: null, // currentFile uuid
    isOpenInner: true,
  }),

  computed: {
    galleryModel: {
      get() {
        const ix = ixByUuid(this.model)(this.files)
        return ix === -1 ? 0 : ix
      },
      set(ix) {
        const file = this.files[ix]
        this.model = file?.uuid ?? null
      },
    },
  },

  watch: {
    // v-model (:value + @input)
    value: {
      handler(value) { this.model = value },
      immediate: true,
    },
    model(model) {
      if (!this.isOpenInner) return
      if (model !== this.value) this.$emit('input', model)
    },

    // :is-open.sync
    isOpen: {
      handler(isOpen) {
        this.isOpenInner = isOpen
        this.$store.commit('defaultLayout/setDisableGlobalShortcuts', isOpen)
      },
      immediate: true,
    },
    isOpenInner(isOpen) {
      if (isOpen !== this.isOpen) this.$emit('update:isOpen', isOpen)

      this.$store.commit('defaultLayout/setDisableGlobalShortcuts', isOpen)
    },
  },

  beforeDestroy() {
    this.$store.commit('defaultLayout/setDisableGlobalShortcuts', false)
  },

  methods: {
    prev() {
      if (!this.files.length) return
      this.galleryModel = this.galleryModel
        ? this.galleryModel - 1
        : this.files.length - 1
    },
    next() {
      if (!this.files.length) return
      this.galleryModel = (this.galleryModel + 1) % this.files.length
    },
  },
}
</script>
