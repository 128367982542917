<template>
  <div
    class="IssueField"
    :class="[
      'IssueField--' + field.type,
      inline && 'IssueField--inline',
    ]"
  >
    <h3
      v-if="!hideTitle"
      class="IssueField__title"
      v-text="field.displayName"
    />
    <component
      :is="displayComponent"
      v-if="displayComponent"
      class="IssueField__display"
      :field="field"
      :value="value"
    />
  </div>
</template>

<script>
import { ISSUE_FIELD_TYPE } from '../constants'

import Issue from '../store/orm/issue'

import IssueFieldBoolean from './IssueFieldBoolean'
import IssueFieldDate from './IssueFieldDate'
import IssueFieldDatetime from './IssueFieldDatetime'
import IssueFieldFloat from './IssueFieldFloat'
import IssueFieldInteger from './IssueFieldInteger'
import IssueFieldMarkdown from './IssueFieldMarkdown'
import IssueFieldRequest from './IssueFieldRequest'
import IssueFieldString from './IssueFieldString'
// import IssueFieldIp from './IssueFieldIp'
// import IssueFieldHostname from './IssueFieldHostname'

export default {
  name: 'IssueField',

  props: {
    issue: { type: Object, required: true },
    field: { type: Object, required: true },
    inline: { type: Boolean, default: false },
    hideTitle: { type: Boolean, default: false },
  },

  computed: {
    displayComponent() {
      return {
        [ISSUE_FIELD_TYPE.TEXT]: IssueFieldString,
        [ISSUE_FIELD_TYPE.TEXT_MD]: IssueFieldMarkdown,
        [ISSUE_FIELD_TYPE.INTEGER]: IssueFieldInteger,
        [ISSUE_FIELD_TYPE.FLOAT]: IssueFieldFloat,
        [ISSUE_FIELD_TYPE.BOOLEAN]: IssueFieldBoolean,
        [ISSUE_FIELD_TYPE.DATE]: IssueFieldDate,
        [ISSUE_FIELD_TYPE.DATETIME]: IssueFieldDatetime,
        [ISSUE_FIELD_TYPE.REQUEST]: IssueFieldRequest,
        [ISSUE_FIELD_TYPE.UUID]: null,
        [ISSUE_FIELD_TYPE.IP]: null, // IssueFieldIp,
        [ISSUE_FIELD_TYPE.HOSTNAME]: null, // IssueFieldHostname,
        [ISSUE_FIELD_TYPE.CVSS_BASE_VECTOR]: IssueFieldString,
        [ISSUE_FIELD_TYPE.CVSS_BASE_SCORE]: IssueFieldInteger,
      }[this.field.type]
    },

    value() {
      const { issue, field: { name: fieldName } } = this
      return Issue.hardcodedFields.includes(fieldName)
        ? issue[fieldName]
        : issue.data[fieldName]
    },

    // valuesAsArray() {
    //   const { field, value } = this
    //   return field.isList
    //     ? value
    //     : [value]
    // },
  },
}
</script>

<style lang="sass" scoped>
.IssueField
  font-size: 16px
  line-height: 24px

  &--inline
    font-size: 13px
    line-height: 160%

  &__title
    font-weight: 500
    font-size: 18px
    line-height: 28px
    margin: 24px 0 16px

  &--inline &__title
    font-style: normal
    font-weight: 500
    font-size: 16px
    line-height: 24px
    margin-top: 18px
    margin-bottom: 4px
    letter-spacing: 0.005em
</style>
