<template>
  <div class="IssueExportRecord">
    <a
      class="IssueExportRecord__link"
      :href="externalUrl"
      target="_blank"
      rel="nofollow noopener noreferrer"
    >
      <!--v-icon
        class="IssueExportRecord__icon"
        v-text="integration.assets.icon"
      /-->

      <span
        class="IssueExportRecord__name"
        v-text="externalName"
      />
    </a>
  </div>
</template>

<script>
import { /* INTEGRATION, */ JIRA } from '../constants'

export default {
  name: 'IssueExportRecord',

  props: {
    // see Issue.exportRecords in swagger spec
    record: { type: Object, required: true },
  },

  computed: {
    // integration() {
    //   const { record: { integrationType } } = this
    //   return INTEGRATION[integrationType]
    // },

    externalName() {
      const { record: { integrationType, metaData } } = this
      if (integrationType === JIRA) {
        return metaData.jiraIssueKey || metaData.jiraIssueName
      }
      throw new Error(`unknown integration: ${integrationType}`)
    },

    externalUrl() {
      const { record: { integrationType, metaData } } = this
      if (integrationType === JIRA) return metaData.jiraIssueUrl
      throw new Error(`unknown integration: ${integrationType}`)
    },
  },
}
</script>

<style lang="sass" scoped>
.IssueExportRecord
  $self: &

  display: flex
  align-items: center
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
  font-size: 14px
  line-height: 24px
  letter-spacing: 0.005em
  max-width: 100%

  &__link
    display: inline-flex
    align-items: center
    text-decoration: none
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

    &:hover, &:active, &:focus
      text-decoration: underline currentColor

  &__icon
    margin-right: 4px
    flex: 0 0 24px

  &__name
    flex: 1
    overflow: hidden
    text-overflow: ellipsis
</style>
