<template>
  <div class="IssueFieldDatetime">
    <component
      :is="datetime.isEmpty || !datetime.isValid ? 'span' : 'time'"
      v-for="(datetime, ix) in dates"
      :key="[ix, datetime.iso].join('~')"
      class="IssueFieldDatetime__datetime"
      :datetimetime="datetime.isEmpty || !datetime.isValid ? null : datetime.iso"
      v-text="datetime.display"
    />
  </div>
</template>

<script>
import moment from 'moment'
import * as R from 'ramda'

import { fmtDt } from '../helpers'

export default {
  name: 'IssueFieldDatetime',

  props: {
    field: { type: Object, required: true },
    value: { type: [Array, String, Number], default: null },
  },

  computed: {
    dates() {
      const { field, value } = this
      return field.isList
        ? R.is(Array, value) ? value.map(v => this.explainDatetime(v)) : [this.explainDatetime(null)]
        : [this.explainDatetime(value)]
    },
  },

  methods: {
    explainDatetime(value) {
      const isEmpty = value == null || value === ''
      const datetime = isEmpty ? null : moment(value)
      const isValid = isEmpty ? true : datetime.isValid()

      return {
        isEmpty,
        isValid,
        iso: datetime && datetime.toISOString(),
        display: R.cond([
          [() => isEmpty, R.always('–')],
          [() => !isValid, R.always('Invalid datetime')],
          [R.T, dt => fmtDt(dt, {
            hideTimeForFarDates: false,
            includeSeconds: true,
          })],
        ])(datetime),
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.IssueFieldDatetime
  &__datetime
    display: block
</style>
