<template>
  <v-hover>
    <!-- slot default value (`= { hover: false }`) for test utils-->
    <template #default="{ hover } = { hover: false }">
      <v-carousel-item
        class="GalleryDialogSlide"
        :src="isImage ? file.url : null"
        :contain="isImage ? true : null"
      >
        <v-slide-y-reverse-transition>
          <v-overlay
            v-show="file.caption && hover"
            absolute
            color="rgba(50, 50, 50, .2)"
            class="GalleryDialogSlide__overlay"
          >
            <v-sheet
              class="GalleryDialogSlide__overlay-caption mt-auto pa-4"
              color="rgba(0, 0, 0, .7)"
              elevation="2"
              rounded
            >
              {{ file.caption }}
            </v-sheet>
          </v-overlay>
        </v-slide-y-reverse-transition>

        <v-slide-y-transition>
          <div
            v-show="!isImage || hover"
            class="GalleryDialogSlide__attachment"
          >
            <v-sheet
              color="rgba(0, 0, 0, .5)"
              elevation="2"
              rounded
              dark
              :style="{
                marginBottom: isImage ? 'auto' : null,
                marginTop: !isImage && !isText ? '48px' : null,
              }"
            >
              <a
                class="GalleryDialogSlide__attachment-link pa-4"
                @click="downloadFile(file)"
              >
                <v-icon
                  x-large
                  class="GalleryDialogSlide__attachment-link-icon"
                  v-text="'mdi-download'"
                />
                <div v-text="file.fileName" />
              </a>
            </v-sheet>

            <pre
              v-if="isText"
              class="GalleryDialogSlide__attachment-preview elevation-2 rounded"
              @click.stop.prevent
              v-text="
                textContent ||
                  (loading && $t('issue.Loading')) ||
                  $t('issue.FilePreviewUnavailableM')
              "
            />
          </div>
        </v-slide-y-transition>
      </v-carousel-item>
    </template>
  </v-hover>
</template>

<script>
import * as R from 'ramda'

import { IMAGE_MIME_TYPES } from '../constants'
import { axiosInstance as axios } from '../api'
import { handleError } from '@/helpers'

export default {
  name: 'GalleryDialogSlide',

  props: {
    file: { type: Object, required: true },
  },

  data: () => ({
    loading: false,
    textContent: null,
  }),

  computed: {
    isImage() {
      const { mimeType } = this.file
      return IMAGE_MIME_TYPES.includes(mimeType)
    },

    isText() {
      const { mimeType } = this.file
      return (
        mimeType.startsWith('text/') ||
        mimeType.includes('+json') ||
        mimeType.includes('/json') ||
        mimeType.includes('+xml') ||
        mimeType.includes('/xml')
      )
    },
  },

  watch: {
    file: {
      handler() {
        if (this.isText) this.tryGetText()
        else this.textContent = null
      },
      immediate: true,
    },
  },

  methods: {
    downloadFile(file) {
      axios.head(file.url, { baseURL: null })
        .then(() => {
          const a = document.createElement('a')
          a.href = file.url
          a.download = ''
          a.click()
        })
        .catch(e => handleError(this.$store, e).catch(() => {}))
    },

    tryGetText() {
      const { file: { url, fileName } } = this

      if (process.env.NODE_ENV === 'test') return

      this.loading = true
      return axios.get(url, { baseURL: null })
        .finally(() => { this.loading = false })
        .then(response => {
          this.textContent = this.prettyDump(response.data)
        })
        .catch(() =>
          console.warn(`File ${fileName} preview is not available`))
    },

    prettyDump(o) {
      return R.is(String, o)
        ? o
        : JSON.stringify(o, null, 4)
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../scss/mixins'

.GalleryDialogSlide
  position: relative

  &__overlay
    pointer-events: none

    ::v-deep .v-overlay__content
      height: 100%
      display: flex
      padding-bottom: 64px + 16px

    &-caption
      pointer-events: all
      user-select: none

  &__attachment
    height: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding-top: 16px
    padding-bottom: 64px

    &-preview
      color: white
      font-family: Consolas, Monospaced, monospace
      padding: 16px
      border: 1px solid rgba(255, 255, 255, .5)
      background: rgba(255, 255, 255, .2)
      margin-top: 18px
      border-radius: 6px
      flex-grow: 1
      min-width: 200px
      max-width: 1000px
      width: clamp(200px, 50vw, 1000px)
      overflow: auto

    &-link
      display: flex
      align-items: center
      justify-content: center
      color: inherit
      text-decoration: none
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1)

      $link: &
      &:hover, &:active
        &, #{$link}-icon
          color: #69D2EF
</style>
